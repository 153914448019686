/* HTML: <div class="loader"></div> */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Change the last value (0.5) to adjust the opacity */
  z-index: 9999; /* Ensure the overlay is on top of other elements */
}
.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000; /* Ensure the loader is on top of the overlay */
}


.loader {
    width: fit-content;
    background-image: linear-gradient(to right, #8E3CF7 0%, #3CC1B7 100%);
    background-clip: text;
    -webkit-background-clip: text; /* For Safari */
    color: transparent;
    font-weight: bold;
    font-family: monospace;
    font-size: 30px;
    clip-path: inset(0 3ch 0 0);
    animation: l4 1s steps(4) infinite;
  }
  .loader:before {
    content:"Loading..."
  }
  @keyframes l4 {to{clip-path: inset(0 -1ch 0 0)}}