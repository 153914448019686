@import "./../../../Colors.css";


.table-container {
  max-height: 1000px;
  /* Set a maximum height for the container */
  overflow-y: auto;
  /* Enable vertical scrolling */
  position: relative;
  /* Required for absolute positioning of the header */
}






.link-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
  color: var(--content-link);

}


.link-button:hover {
  color: var(--content-link-hover);
}



.btn:disabled {
  border-color: transparent;
  cursor: not-allowed;
  color: #666666;
}