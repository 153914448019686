@import  "../../Colors.css";

.custom-h1{
    color: var(--content-primary);
    letter-spacing: 5px;
    font-size: 6em;
    font-weight: bold;
}


.custom-tile{
    background-color: var(--content-secondary);
    min-height: 200px;
    width: 100%;
    border-radius: 4px;
    letter-spacing: 3px;
    font-weight: bold;
    color: rgb(248, 249, 250);
    box-shadow: 2px 2px 2px rgba(128, 128, 128, 0.502);
}

.custom-h2{
    color: var(--content-primary);
    letter-spacing: 1px;
    font-weight: bold;
}


.custom-button-download{
    
}





  
