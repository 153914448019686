/* Custom Add button to add more SMCs*/
.custom-add-button {
    --custom-btn-color: #000000; /* Text color */
    --custom-btn-bg: transparent; /* Background color */
    --custom-btn-border-color: #e5e6de; /* Border color */
    --custom-btn-hover-color: #ffffff; /* Text color on hover */
    --custom-btn-hover-bg: #0d6dfd76; /* Background color on hover */
    --custom-btn-hover-border-color:#0d6dfd76; /* Border color on hover */
    --custom-btn-focus-shadow-rgb: 0,0,0; /* Focus shadow color (black) */
    --custom-btn-active-color: #ffffff; /* Text color when active */
    --custom-btn-active-bg:#0d6dfd76; /* Background color when active */
    --custom-btn-active-border-color: #dee2e6; /* Border color when active */
    --custom-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); /* Box shadow when active */
    --custom-btn-disabled-color: #888888; /* Text color when disabled */
    --custom-btn-disabled-bg: #dddddd; /* Background color when disabled */
    --custom-btn-disabled-border-color: #aaaaaa; /* Border color when disabled */
    --custom-gradient: none; /* No gradient */
    --bs-border-radius-button: 0.5em;
}

.custom-add-button {
    color: var(--custom-btn-color);
    background-color: var(--custom-btn-bg);
    border-color: var(--custom-btn-border-color);
    border-radius: 0 0 var(--bs-border-radius-button) var(--bs-border-radius-button);
    box-shadow: inset 0 0.2rem #cfe2ff;
}

.custom-remove-button {
    color: var(--custom-btn-color);
    background-color: var(--custom-btn-bg);
    border-color: var(--custom-btn-border-color);
    border-radius: 0 0 var(--bs-border-radius-button) var(--bs-border-radius-button);
    box-shadow: inset 0 0.2rem #cc676798;
}

.custom-add-button:hover {
    color: var(--custom-btn-hover-color);
    background-color: var(--custom-btn-hover-bg);
    border-color: var(--custom-btn-hover-border-color);
    box-shadow: none;
}

.custom-remove-button:hover {
    color: var(--custom-btn-hover-color);
    background-color: #DA4453;
    border-color: #7e1923;
    box-shadow: none;
}

.custom-add-button:active {
    color: var(--custom-btn-active-color);
    background-color: var(--custom-btn-active-bg);
    border-color: var(--custom-btn-active-border-color);
}

.custom-add-button:disabled {
    color: var(--custom-btn-disabled-color);
    background-color: var(--custom-btn-disabled-bg);
    border-color: var(--custom-btn-disabled-border-color);
}

.custom-add-button:not(:disabled) {
    cursor: pointer;
}

.custom-add-button.gradient {
    background-image: var(--custom-gradient);
    background-size: 100%;
    background-repeat: no-repeat;
    color: #ffffff; /* Text color on gradient button */
}